import { i18n } from "@/plugins/i18n"
import { Trans } from './plugins/translation'
import ClickOutside from '@/directives/clickOutside'
import VCalendar from 'v-calendar';
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import VueCookies from 'vue-cookies';

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.prototype.GoogleTag = window.googletag

Vue.config.productionTip = false

Vue.use(VueCookies);
Vue.use(VCalendar)
Vue.directive('click-outside', ClickOutside)

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.get("source") == "ia") {
  Vue.prototype.$mode = "ia"
} else {
  Vue.prototype.$mode = "noq"
}

Vue.prototype.$beiersdorf = process.env.VUE_APP_BEIERSDORF_MODE === "true"
Vue.prototype.$showAds = process.env.VUE_APP_ADS_ENABLED === "true"
  
Vue.prototype.$gtag = window.gtag

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
