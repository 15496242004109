import { DateTime } from "luxon";

export function buildQuery(timeRange) {
  return {
    "size": 0,
    "query": { "bool": { "filter": [
      {"range": {
        "min_booking_date": {
          "lte": "now"
        }
      }},
      {"range": {
        "max_booking_time": {
          "gte": "now"
        }
      }},
      { "range": {
        "checkin_at": {
          "gte": timeRange.start,
          "lte": timeRange.end
        }
      }},
      {"term": {
        "service_name.keyword": "eucerin-skin-consulting" 
      }}
    ]}}, 
    "aggs": {
      "locations": {
        "terms": {
          "field": "vl_gym_id",
          "size": 10000
        },
        "aggs": {
          "gym": {
            "top_hits": {
              "size":1
            }
          },
          "first_available_date": {
            "min": {
              "field": "checkin_at"
            }
          },
          "availability_per_day": {
            "date_histogram": {
              "field": "checkin_at",
              "calendar_interval": "day",
              "time_zone": "Europe/Rome",
              "min_doc_count": 1
            },
            "aggs": {
              "free_spots_sum": {
                "sum": {
                  "field": "free_spots"
                }
              }
            }
          }
        }
      }
    }
  } 
}

export function normaliseElasticData(data) {
  const locationArr = data.aggregations?.locations.buckets
  if (!locationArr) return null;

  return locationArr.map(loc => {
    
    const locInfo = loc.gym.hits.hits[0]._source
    
    return {
      slug: locInfo.slug,
      lat: locInfo.lat,
      lng: locInfo.lng,
      name: locInfo.name,
      free: free_spots_today(loc.availability_per_day.buckets[0]),
      first_available: DateTime.fromMillis(loc.first_available_date.value),
      address: locInfo.address,
      zip: locInfo.zip,
      city: locInfo.city,
      country: locInfo.country,
      bookedOut: false,
      appIntegrations: locInfo.app_integrations,
      slimTestbuchen: locInfo.slim_testbuchen,
      website: locInfo.website,
      prices: locInfo.prices
    }
  })
}

function free_spots_today(first_bucket) {
  const today = DateTime.now().startOf('day')
  const bucket_date = DateTime.fromISO(first_bucket.key_as_string);
  const isToday = bucket_date.hasSame(today, 'day');
  if (isToday) return first_bucket?.free_spots_sum?.value
  return 0
}
