<template>
  <div>
    <v-date-picker v-model="selectedTimespan" is-range is-expanded 
      :columns="$screens({ md: 2 }, 1)" 
      :rows="$screens({ sm: 1 }, 2)" 
      :min-date="new Date()"
      >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="range-selector-form-container">
          <div>
            <span v-on="inputEvents.start">{{ inputValue.start }} - {{ inputValue.end }}</span>
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>

import { DateTime } from 'luxon';
import { EventBus } from '@/plugins/event-bus.js';

export default {
  data() {
    return {
      calendarVisible: true,
      selectedTimespan: null
    }
  },
  beforeMount(){
    this.selectedTimespan = this.defaultTimespan()
  },
  watch: {
    selectedTimespan: {
      deep: true,
      handler(valToEmit) {
        const now = DateTime.now()
        const startToday = DateTime.fromJSDate(valToEmit.start).hasSame(now, "day")
        const start = startToday ? DateTime.fromJSDate(valToEmit.start) : DateTime.fromJSDate(valToEmit.start).startOf('day')
        const vte = {
          start: start.toJSDate(),
          end: DateTime.fromJSDate(valToEmit.end).endOf('day').toJSDate()
        }
        EventBus.$emit('date-time-filter-changed', vte)
      }
    },
  },
  methods: {
    parsedDate(dt) {
      return DateTime.fromJSDate(dt).toFormat("yyyy-MM-dd")
    },
    defaultTimespan() {
      const start = new Date();
      const end = DateTime.fromJSDate(start).endOf('day').plus({months: 3}).toJSDate()
      return {
        start: start, 
        end: end
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.range-selector-form-container {
  background-color: #ffffff;
  padding: 4px 8px;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.calendar-input {
  flex-grow: 1;
  padding: 8px 4px;
  text-align: center;
  border: 1px solid grey;
  border-radius: 4px;
}
</style>
