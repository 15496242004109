import axios from 'axios'

import { buildQuery as bsdQuery, normaliseElasticData as bsdData } from "./queries/beiersdorf_locator_query"
import { buildQuery as locatorQuery, normaliseElasticData as locatorData } from "./queries/locator_query"

const BEIERSDORF_MODE = process.env.VUE_APP_BEIERSDORF_MODE === "true"

export default {
  data(){
    return {
      elasticUrl: process.env.VUE_APP_ELASTIC_URL,
      dateTimeFilter: null,
    }
  },
  methods: {
    async getLocationsByDateTime(dateTimeFilter) {
      this.dateTimeFilter = dateTimeFilter
      if (BEIERSDORF_MODE) {
        return axios.get(this.elasticUrl, {
          params: {
            source: JSON.stringify(bsdQuery(this.dateTimeFilter)),
            source_content_type: 'application/json'
          },
        })
        .then(resp => bsdData(resp.data))
        .catch(err => console.log(err))
      } else {
        const iaMode = this.$mode === "ia"
        return axios.get(this.elasticUrl, {
          params: {
            source: JSON.stringify(locatorQuery(this.dateTimeFilter, iaMode)),
            source_content_type: 'application/json'
          },
        })
        .then(resp => locatorData(resp.data))
        .catch(err => console.log(err))
      }
    }
  }
}