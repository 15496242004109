<template>
  <div class="noq-cookie-policy-page">
    <div class="noq-cookie-policy-header">
      <div class="noq-cookie-policy-header-helper">
        <header-info />
      </div>
    </div>
    <div id="noqCookiePolicyBody" class="noq-cookie-policy-body">
      <h1>Cookie Policy</h1>
    </div>
    <footer-info />
  </div>
</template>

<script>
import FooterInfo from '@/components/FooterInfo.vue'
import HeaderInfo from '@/components/HeaderInfo.vue'

export default {
  name: 'CookiesPolicy',
  components: {
    FooterInfo,
    HeaderInfo
  },
  mounted() {
    let cookiesScript = document.createElement('script')
    cookiesScript.setAttribute('src', 'https://consent.cookiebot.com/c680456f-ff0b-4128-9884-85fdbb500359/cd.js')
    cookiesScript.setAttribute('id', 'CookieDeclaration')
    cookiesScript.async = true
    document.getElementById('noqCookiePolicyBody').appendChild(cookiesScript)
  }
}
</script>

<style lang="scss" scoped>
.noq-cookie-policy-page {
  margin: 0 auto;
  position: relative;
  min-height: 100%;
  margin-bottom: -21px;

  .noq-cookie-policy-header {
    background-color: $color_white;

    .noq-cookie-policy-header-helper {
      margin: 0 auto;
      max-width: 694px;
      padding: $spacing-s 0;
    }
  }

  .noq-cookie-policy-body {
    max-width: 694px;
    margin: 0 auto;
    min-height: calc(100vh - 304px);
    padding: $spacing-xxl;
    margin-bottom: $spacing-6xl;

    &__white-background {
      background-color: $color_white;
    }
  }
}
</style>

<style lang="scss">
.noq-cookie-policy-header-helper {
  .home-header {
    padding: unset;
    background-color: unset;
  }
}
</style>
