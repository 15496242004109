<template>
  <div
    class="map-placeholder-container"
    :style="{'background-image': `url(${require('@/assets/images/background_placeholder.jpg')})`}"
  >
    <div class="map-cookie-cta">
      <p>
        Ich bin damit einverstanden, dass Google Maps meine Daten zur Anzeige der Karte verarbeitet und außerhalb der EU überträgt, 
        einschließlich meiner IP-Adresse.
      </p>
      <button class="noq-button noq-button__primary" @click="consentToMaps()">Karte anzeigen</button>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      consentToMaps() {
        this.$emit("agree")
      }
    }
  }
</script>