<template>
  <div>
    <div v-if="eucerinLogo" class="eucerin-logo-container">
      <img v-if="$beiersdorf" :src="eucerinLogo" height="60px" alt="">  
    </div>
    <google-ads v-if="adsVisible" :placement="'desktop'"></google-ads>
    <div class="home-header">
      <div class="home-header-location">
        <div class="location-logo">
          <img :src="locationLogo">
        </div>
        <div class="location-name">
          {{ $t('views.Home.headerText') }}
        </div>
      </div>
      <div class="home-header-language-selector">
        <language-selector />
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue'
import GoogleAds from '@/components/GoogleAds.vue'

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  components: {
    LanguageSelector,
    GoogleAds
  },
  computed: {
    eucerinLogo() {
      if (!this.$beiersdorf) return null;
      return this.windowWidth > 996 ? require('@/assets/images/eucerin-logo.png') : null
    },
    locationLogo() {
      return require('@/assets/images/eucerin-logo.png')
      // return process.env.VUE_APP_LOCATOR_LOGO
    },
    adsVisible() {
      // return false
      return this.windowWidth > 996 && this.$showAds
    }
  },
  mounted() {  
    window.addEventListener('resize', () => {this.windowWidth = window.innerWidth});
  },
}
</script>

<style lang="scss" scoped>
.eucerin-logo-container {
  display: flex;
  justify-content: center;
}
.home-header {
  padding: $spacing-m;
  background-color: $color_light-gray;

  .home-header-location {
    display: inline-block;
    width: 65%;

    .location-logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: $spacing-xs;
      // cursor: pointer;

      img {
        width: 23px;
        padding-top: $spacing-4xs;
      }
    }

    .location-name {
      @include text_subtitle-2;
      display: inline-block;
      vertical-align: middle;
      // cursor: pointer;
    }
  }

  .home-header-language-selector {
    display: inline-block;
    width: 35%;
    text-align: right;
  }
}
</style>
